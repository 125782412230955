@import '~@nswdoe/doe-ui-core/styles/variables.scss';

$body-font-family: Arial, sans-serif;
$heading-font-family: Arial, sans-serif;

$color-primary: #041e42;
$grey-darken1: #6D7079;
$grey-dark: #121212;
$color-selected-item: #c8dcf0;
$color-placeholder: #4C4F55;
$color-white: #FFFFFF;
$color-grey-3: #B3B3B3;
$color-focus: #a8d6ff;
$color-tertiary: #6cace4;
$color-text-body: #002040;

$list-item-avatar-first-child-margin:10px;

$snackbar-content-font-size: 1rem;
$snackbar-wrapper-margin: 1.5rem;

$btn-outline-border-width: 2px;
